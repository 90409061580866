import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { PageTemplate } from '../templates/page'
import { get, list, quantity, total } from 'cart-localstorage'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { roundAndFix, addVatTo, removeVatFrom } from '../scripts/helpers'
import { loadStripe } from '@stripe/stripe-js'
import SiteMetadata from '../components/SiteMetadata'
import Button from '../components/shortcodes/Button'
import ProductImageById from '../components/ProductImageById'
import CheckoutPageStyles from '../styles/CheckoutPage.module.sass'

const pageProps = {
  title: "Order Details",
  pageIdentifier: "checkout",
  pageClass: "hide-cart footer-dimmed",
  showCta: false,
  showContactForm: false,
}

// Stripe constants
// Live Mode
// const taxRates = ['txr_1Lx6mDEiQVntVEkkn3TsXcEL']

// Development Mode
// const taxRates = ['txr_1LxD4XEiQVntVEkkHqSyWcS7']

const PaymentPage = () => {
  const { countiesWithTiming, maxProductQuantity, nationwideDeliveryCharge, minimumQuantityForVolumeDiscount } = SiteMetadata()

  const Settings = require('../data/site-settings.json')

  // Define states
  const [cart, setCart] = useState(typeof window !== 'undefined' ? list() : null)
  const [deliveryRecipientsCount, setDeliveryRecipientsCount] = useState(0)
  const [deliveryTypesPerItem, setDeliveryTypesPerItem] = useState({})
  const [countiesWithTimingPerItem, setCountiesWithTimingPerItem] = useState({})
  const [totalQuantity, setTotalQuantity] = useState(typeof window !== 'undefined' ? calculateTotalQuantity() : 0)
  const [discountApplied, setDiscountApplied] = useState(typeof window !== 'undefined' ? calculateDiscount() : 0)
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
  const [deliveriesList, setDeliveriesList] = useState({})
  const [billingDeliveryCounty, setBillingDeliveryCounty] = useState()
  const selectedCurrency = useSelector((state) => state.currency)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Calculate initial number of delivery recipients
      calculateDeliveryRecipients()

      setTimeout(() => {
        setAllToCollection()
        setBillingDeliveryCounty('Dublin')
      }, 1000)
    }
  }, [])

  // Prevent Gatsby from prerendering this page
  if (typeof window == 'undefined') {
    return false
  }

  function convertTimestampToHTML5Date(date) {
    var dd = date.getDate()
    var mm = date.getMonth() + 1 // January is 0
    var yyyy = date.getFullYear()

    if (dd < 10) {
      dd = '0' + dd
    }
    if (mm < 10) {
      mm = '0' + mm
    }

    return yyyy + '-' + mm + '-' + dd
  }

  function convertCurrency(amount, currency = selectedCurrency) {
    const conversionRateGbp = Settings.availableCurrencies.GBP.conversionRate
    const conversionRateUsd = Settings.availableCurrencies.USD.conversionRate
    const conversionRateAud = Settings.availableCurrencies.AUD.conversionRate

    if (currency === 'GBP') {
      return Math.ceil(amount / conversionRateGbp * 1.02)
    }
    else if (currency === 'USD') {
      return Math.ceil(amount / conversionRateUsd * 1.02)
    }
    else if (currency === 'AUD') {
      return Math.ceil(amount / conversionRateAud * 1.02)
    }

    return amount
  }

  const currencySymbol = Settings.availableCurrencies[selectedCurrency].symbol

  function updateDeliveryTypesPerItem(subItemId, newDeliveryType) {
    let _deliveryTypesPerItem = deliveryTypesPerItem

    if (newDeliveryType === 'delivery_gift_recipient' || newDeliveryType === 'delivery_billing_address') {
      _deliveryTypesPerItem[subItemId] = newDeliveryType
    }
    else {
      if ( _deliveryTypesPerItem[subItemId] ) {
        delete _deliveryTypesPerItem[subItemId]
      }
    }

    setDeliveryTypesPerItem({ ..._deliveryTypesPerItem })
  }

  function updateCountiesWithTimingPerItem(subItemId, newCounty) {
    let _countiesWithTimingPerItem = countiesWithTimingPerItem

    _countiesWithTimingPerItem[subItemId] = newCounty

    setCountiesWithTimingPerItem({ ..._countiesWithTimingPerItem })
  }

  // Create available dates logic
  // Min: In 2 days
  let minDate = new Date()
  minDate.setDate(minDate.getDate() + 2)
  minDate = convertTimestampToHTML5Date(minDate)

  // Max: in 6 months
  let maxDate = new Date()
  maxDate.setDate(maxDate.getDate() + 180)
  maxDate = convertTimestampToHTML5Date(maxDate)

  // Increase/Decrease quantity
  function changeQty(operation, id) {
    if (operation === 'decrease') {
      quantity(id, -1)
    } else {
      if (get(id).quantity >= maxProductQuantity) {
        return false
      } else {
        quantity(id, 1)
      }
    }

    setCart(list())

    let newTotalQuantity = calculateTotalQuantity()

    setTotalQuantity(newTotalQuantity)
    setDiscountApplied(calculateDiscount(false, newTotalQuantity))
    generateDeliveriesList()
  }

  /**
   * Calculate discount from volume or code
   * 
   * @param {string}  [checkForCode=false]
   * @param {number}  [providedQuantity]
   */
  function calculateDiscount(checkForCode, providedQuantity) {
    // Grab voucher code field
    const field = document.querySelector('input[name="billing_voucher"]')
    const validityErrorMessage = 'Voucher code not valid'
    const validCode = 'hmpc5'
    let code = ''

    if (field) {
      code = field.value
    }

    if (checkForCode) {
      if (code) {
        if (discountApplied === 0.1) {
          showSuccessMessage('There is a bigger discount already applied to your order')

          return 0.1
        }
        else if (code.toLowerCase() === validCode) {
          showSuccessMessage('Your code has been applied!')

          return 0.05
        }
        else {
          throwPopupError(validityErrorMessage)
        }
      }
      else {
        throwPopupError(validityErrorMessage)
      }
    }
    else {
      let usableQuantity = 0

      if (providedQuantity || providedQuantity === 0) {
        usableQuantity = providedQuantity
      }
      else {
        usableQuantity = calculateTotalQuantity()
      }

      if (usableQuantity >= minimumQuantityForVolumeDiscount) {
        return 0.1
      }
      else if (code.toLowerCase() === validCode) {
        return 0.05
      }
    }

    return 0
  }

  /**
   * Calculate total number of products in cart
   */
  function calculateTotalQuantity() {
    let productsInCart = list()

    if (productsInCart) {
      let totalQuantity = 0

      productsInCart.forEach(item => {
        totalQuantity += item.quantity
      })

      return totalQuantity
    }

    return null
  }

  // Calculate total number of delivery recipients and the charge
  function calculateDeliveryRecipients() {
    let data = []
    let recipientCount = 0

    // Grab matching fields
    let fields = document.querySelectorAll('select[data-field-type="delivery_type"]')

    // Create a simple array of values found
    for (let field of fields) {
      data.push(field.value)
    }

    // Add together all "delivery_gift_recipient" values
    data.forEach(item => {
      if (item === 'delivery_gift_recipient') {
        recipientCount++
      }
    })

    // Add billing address delivery if chosen
    if (data.includes('delivery_billing_address')) {
      recipientCount++
    }

    // Update deliveryRecipientsCount state
    setDeliveryRecipientsCount(recipientCount)
  }

  // Generate deliveries list
  function generateDeliveriesList() {
    let _deliveriesList = {}

    // Grab matching fields
    let fields = document.querySelectorAll('select[data-field-type="delivery_type"]')

    // Create a simple array of values found
    for (let field of fields) {
      _deliveriesList[field.name] = field.value
    }

    setDeliveriesList(_deliveriesList)
  }

  // Set all deliveries to collection
  function setAllToCollection() {
    // Grab matching fields
    let fields = document.querySelectorAll('select[data-field-type="delivery_type"]')

    // Change value to collection
    for (let field of fields) {
      field.value = 'collection'

      field.dispatchEvent(new Event('change', { 'bubbles': true }))
    }

    generateDeliveriesList()
  }

  // Calculate totals
  // let amountSubtotal = removeVatFrom(total())
  let amountSubtotal = total()
  // let amountDelivery = removeVatFrom( Number(deliveryRecipientsCount) * Number(nationwideDeliveryCharge) )
  let amountDelivery = Number(deliveryRecipientsCount) * Number(nationwideDeliveryCharge)
  let amountDiscount = roundAndFix( (Number(amountSubtotal) + Number(amountDelivery)) * Number(discountApplied) )

  // let amountTotal = addVatTo(Number(amountSubtotal) + Number(amountDelivery) - Number(amountDiscount) )
  let amountTotal = Number(amountSubtotal) + Number(amountDelivery) - Number(amountDiscount)

  // let amountVat = roundAndFix( Number(amountTotal) - Number(amountSubtotal) + Number(amountDiscount) - Number(amountDelivery) )

  /**
   * Helper function to throw error via Toastify
   * 
   * @param {string}  message
   * @param {string}  [element] // Scroll to this element
   */
  function throwPopupError(message, element) {
    toast.error(message, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
    })

    if (element) {
      element.scrollIntoView()
      element.focus()
    }
  }

  /**
   * Helper function to show a success message via Toastify
   * 
   * @param {string}  message
   * @param {string}  [element] // Scroll to this element
   */
  function showSuccessMessage(message, element) {
    toast.success(message, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
    })

    if (element) {
      element.scrollIntoView()
      element.focus()
    }
  }

  /**
   * Helper function to validate an input
   * 
   * @param {string}  input
   * @param {string}  type: text, number, phone, email
   * @param {number}  [minLength=1]
   */
  function isInputValid(input, type, minLength = 1) {
    if ( 
      input && 
      input.value && 
      input.value.trim().length >= minLength ) {
        if (type === 'text') {
          // It should be valid if minLength is satisfied
          return true
        }
        else if (type === 'number') {
          // It should be valid if minLength is satisfied
          return true
        }
        else if (type === 'phone') {
          // It should be valid if minLength is satisfied
          return true
        }
        else if (type === 'email') {
          let regex = /\S+@\S+\.\S+/

          return regex.test(input.value)
        }
    }
    
    return false
  }

  // Send user to Stripe
  async function processForm(event) {
    event.preventDefault()

    // Temporarily disable form submit button
    setSubmitButtonDisabled(true)
    setTimeout(() => setSubmitButtonDisabled(false), 4000)
    
    const partialErrorMessage = 'This field is empty or invalid: '
    const genericErrorMessage = 'Something went wrong, please refresh the page and try again or send us an enquiry by email.'

    let data = {
      ieOrIntl: 'Ireland'
    }

    // Order metadata - Stringify all
      let metadata = {}

      // Full name
      const fullNameField = document.querySelector('input[name="billing_name"]')

      if ( isInputValid( fullNameField, 'text', 3 ) ) {
        metadata.billing_name = fullNameField.value
      }
      else {
        throwPopupError( partialErrorMessage + 'Billing Full Name', fullNameField )
        return
      }

      // Company - Optional
      const companyField = document.querySelector('input[name="billing_company"]')

      if (isInputValid(companyField, 'text')) {
        metadata.billing_company = companyField.value
      }

      // Phone
      const phoneField = document.querySelector('input[name="billing_phone"]')

      if (isInputValid(phoneField, 'phone', 6)) {
        metadata.billing_phone = phoneField.value
      }
      else {
        throwPopupError(partialErrorMessage + 'Billing Phone Number', phoneField)
        return
      }

      // Email
      const emailField = document.querySelector('input[name="billing_email"]')

      if (isInputValid(emailField, 'email', 5)) {
        metadata.billing_email = emailField.value
      }
      else {
        throwPopupError(partialErrorMessage + 'Billing Email', emailField)
        return
      }

      // Full address
      const fullAddressField = document.querySelector('input[name="billing_address"]')

      if (isInputValid(fullAddressField, 'text', 3)) {
        metadata.billing_address = fullAddressField.value
      }
      else {
        throwPopupError(partialErrorMessage + 'Billing Address', fullAddressField)
        return
      }

      // Town/City
      const townCityField = document.querySelector('input[name="billing_town_city"]')

      if (isInputValid(townCityField, 'text')) {
        metadata.billing_town_city = townCityField.value
      }
      else {
        throwPopupError(partialErrorMessage + 'Billing Town/City', townCityField)
        return
      }

      // County
      const countyField = document.querySelector('select[name="billing_county"]')

      if (isInputValid(countyField, 'text')) {
        metadata.billing_county = countyField.value
      }
      else {
        throwPopupError(partialErrorMessage + 'Billing County', countyField)
        return
      }

      // Eircode
      const eircodeField = document.querySelector('input[name="billing_eircode"]')

      if (isInputValid(eircodeField, 'text', 7)) {
        metadata.billing_eircode = eircodeField.value
      }
      else {
        throwPopupError(partialErrorMessage + 'Billing Eircode', eircodeField)
        return
      }

      // Delivery to Billing Address Date & Time - Optional
      const timeableBillingCheck = document.querySelector('input[name="delivery_date_billing"]:not(.parent-hidden)')

      if (
        timeableBillingCheck &&
        countiesWithTiming.includes(countyField.value)
      ) {
        const deliveryDate = document.querySelector('input[name="delivery_date_billing"]')

        if (isInputValid(deliveryDate, 'text', 5)) {
          const splitDate = deliveryDate.value.split('-')
          let processedDate = deliveryDate.value

          if (splitDate[2]) {
            processedDate = splitDate[2] + '/' + splitDate[1] + '/' + splitDate[0]
          }

          metadata.billing_delivery_date = processedDate
        }

        const deliveryTime = document.querySelector('select[name="delivery_time_billing"]')

        if (isInputValid(deliveryTime, 'text', 1)) {
          let deliveryTimeReadable = deliveryTime.value

          if (deliveryTime.value === 'any') {
            deliveryTimeReadable = 'Anytime (8am-5pm)'
          }
          else if (deliveryTime.value === 'morning') {
            deliveryTimeReadable = 'Morning (8am-12pm)'
          }
          else if (deliveryTime.value === 'afternoon') {
            deliveryTimeReadable = 'Afternoon (12pm-5pm)'
          }

          metadata.billing_delivery_time = deliveryTimeReadable
        }
      }

    // Discounts array | discounts: [{coupon: couponId}]
    if (discountApplied > 0) {
      let couponId = ''

      if (discountApplied === 0.05) {
        couponId = 'hmpc5'
      }
      else if (discountApplied === 0.1) {
        couponId = 'volume_discount'
      }

      if (couponId.length && couponId.length > 0) {
        data.discounts = [{coupon: couponId}]
      }
    }

    // Line Items array
    let lineItems = []

    // Products
    const productBoxes = document.querySelectorAll('.single-product-box')

    if (productBoxes) {
      productBoxes.forEach(product => {
        // line item
        let lineItem = {
          // static data; qty is always 1 for hampers/boxes
          quantity: 1,
          // tax_rates: taxRates,
        }

        // price_data
        let priceData = {
          // static data
          currency: 'eur',
          // tax_behavior: 'exclusive',
        }

        // unit_amount (ex VAT) * 100
        let unitPrice = product.dataset.unitPrice || 0

        if (
          unitPrice && 
          Number(unitPrice) > 0) {
            // let _unitPrice = removeVatFrom( Number(unitPrice) )
            let _unitPrice = Number(unitPrice)
            _unitPrice = Number(_unitPrice) * 100

            // Push to priceData
            priceData.unit_amount = parseInt(_unitPrice)
        }
        else {
          throwPopupError(genericErrorMessage)
          return
        }

        // product_data object
        let productData = {}

        // name // Ex: Small Hamper (#1)
        const productName = product.dataset.productName
        const productIndex = product.dataset.productIndex

        if (productName && productIndex) {
          productData.name = productName + ' (#' + productIndex + ')'
        }
        else {
          throwPopupError(genericErrorMessage)
          return
        }

        // Product metadata object
        let productMetadata = { type: 'product' }

        // product id
        const productId = product.dataset.id

        if (productId) {
          productMetadata.product_id = productId
        }
        else {
          throwPopupError(genericErrorMessage)
          return
        }

        // unique id
        const subProductId = product.dataset.uniqueId

        if (productId) {
          productMetadata.sub_product_id = subProductId
        }
        else {
          throwPopupError(genericErrorMessage)
          return
        }

        // Product description
        let productDescription = ''

        // Recipient Name - optional
        const recipientNameField = product.querySelector('input[name="name_' + String(subProductId) + '"]')

        if (isInputValid(recipientNameField, 'text', 3)) {
          productMetadata.recipient_name = recipientNameField.value

          productDescription += 'Recipient Name: ' + recipientNameField.value + ' | '
        }

        // Personalised Message: - optional
        const personalisedMessageField = product.querySelector('input[name="message_' + String(subProductId) + '"]')

        if (isInputValid(personalisedMessageField, 'text')) {
          productMetadata.personalised_message = personalisedMessageField.value

          productDescription += 'Personalised Message: ' + personalisedMessageField.value + ' | '
        }

        // Collection or Delivery Type
        const collectionOrDeliveryType = product.querySelector('select[name="delivery_type_' + String(subProductId) + '"]')

        if (collectionOrDeliveryType && collectionOrDeliveryType.value === 'collection' ) {
          productMetadata.delivery_type = collectionOrDeliveryType.value

          productDescription += 'Collection in Dublin 10 | '
        }
        else if (collectionOrDeliveryType && collectionOrDeliveryType.value === 'delivery_billing_address') {
          productMetadata.delivery_type = collectionOrDeliveryType.value

          productDescription += 'Delivery Type: Deliver to my Billing Address | '
        }
        else if (collectionOrDeliveryType && collectionOrDeliveryType.value === 'delivery_gift_recipient') {
          productMetadata.delivery_type = collectionOrDeliveryType.value

          productDescription += 'Delivery Type: Deliver to Gift Recipient | '
        }
        else {
          throwPopupError(genericErrorMessage)
          return
        }

        // If gift recipient, add more fields
        if (collectionOrDeliveryType && collectionOrDeliveryType.value === 'delivery_gift_recipient') {
          // Delivery Address
          const recipientAddressField = product.querySelector('input[name="address_' + String(subProductId) + '"]')

          if (isInputValid(recipientAddressField, 'text', 3)) {
            productMetadata.recipient_address = recipientAddressField.value

            productDescription += 'Delivery Address: ' + recipientAddressField.value + ' | '
          }
          else {
            throwPopupError(partialErrorMessage + 'Gift Recipient Address', recipientAddressField)
            return
          }

          // Town/City
          const recipientTownCity = product.querySelector('input[name="town_city_' + String(subProductId) + '"]')

          if (isInputValid(recipientTownCity, 'text')) {
            productMetadata.recipient_town_city = recipientTownCity.value

            productDescription += 'Town/City: ' + recipientTownCity.value + ' | '
          }
          else {
            throwPopupError(partialErrorMessage + 'Recipient Town/City', recipientTownCity)
            return
          }

          // County
          const recipientCountyField = product.querySelector('select[name="county_' + String(subProductId) + '"]')

          if (isInputValid(recipientCountyField, 'text')) {
            productMetadata.recipient_county = recipientCountyField.value

            productDescription += 'County: ' + recipientCountyField.value + ' | '
          }
          else {
            throwPopupError(partialErrorMessage + 'Recipient County', recipientCountyField)
            return
          }

          // Eircode
          const recipientEircode = product.querySelector('input[name="eircode_' + String(subProductId) + '"]')

          if (isInputValid(recipientEircode, 'text')) {
            productMetadata.recipient_eircode = recipientEircode.value

            productDescription += 'Eircode: ' + recipientEircode.value + ' | '
          }

          // Phone No.
          const recipientPhone = product.querySelector('input[name="phone_' + String(subProductId) + '"]')

          if (isInputValid(recipientPhone, 'phone', 6)) {
            productMetadata.recipient_phone = recipientPhone.value

            productDescription += 'Phone: ' + recipientPhone.value + ' | '
          }
          else {
            throwPopupError(partialErrorMessage + 'Recipient Phone Number', recipientPhone)
            return
          }

          // Check for timeable county
          if (countiesWithTiming.includes(recipientCountyField.value)) {
            // It's in a timeable county

            // Delivery date
            const deliveryDate = product.querySelector('input[name="date"]')

            if (isInputValid(deliveryDate, 'text', 5)) {
              const splitDate = deliveryDate.value.split('-')
              let processedDate = deliveryDate.value

              if ( splitDate[2] ) {
                processedDate = splitDate[2] + '/' + splitDate[1] + '/' + splitDate[0]
              }

              productMetadata.delivery_date = processedDate

              productDescription += 'Preferred date: ' + processedDate + ' | '
            }

            // Delivery time
            const deliveryTime = product.querySelector('select[name="delivery_time_' + String(subProductId) + '"]')

            if (isInputValid(deliveryTime, 'text', 1)) {
              productMetadata.delivery_time = deliveryTime.value

              let deliveryTimeReadable = deliveryTime.value

              if ( deliveryTime.value === 'any' ) {
                deliveryTimeReadable = 'Anytime (8am-5pm)'
              }
              else if (deliveryTime.value === 'morning') {
                deliveryTimeReadable = 'Morning (8am-12pm)'
              }
              else if (deliveryTime.value === 'afternoon') {
                deliveryTimeReadable = 'Afternoon (12pm-5pm)'
              }

              productDescription += 'Preferred time: ' + deliveryTimeReadable + ' | '
            }
          }
          else {
            // Not in a timeable county
            productDescription += 'Expected delivery within 1-5 working days | '
          }
        }

        // Add nested sub-arrays/objects
        productData.metadata = productMetadata
        productData.description = productDescription.slice(0, productDescription.lastIndexOf(' | '))
        priceData.product_data = productData
        lineItem.price_data = priceData

        // Add line item to the main array
        lineItems.push(lineItem)
      })
    }
    else {
      // There are no product boxes on the page
      throwPopupError(genericErrorMessage)

      return
    }

    // Delivery as single product
    let deliveryLineItem = {
      // static data; qty is always 1 for hampers/boxes
      quantity: 1,
      // tax_rates: taxRates,
    }

    // Delivery price_data
    let deliveryPriceData = {
      // static data
      currency: 'eur',
      // tax_behavior: 'exclusive',
    }

    // unit_amount (ex VAT) * 100
    if (Number(amountDelivery) === 0) {
      // Push to priceData
      deliveryPriceData.unit_amount = 0
    }
    else if (Number(amountDelivery) > 0) {
      let _amountDelivery = Number(amountDelivery) * 100

      // Push to priceData
      deliveryPriceData.unit_amount = parseInt(_amountDelivery)
    }
    else {
      throwPopupError(genericErrorMessage)
      return
    }

    // delivery product_data object
    let deliveryProductData = {}

    // delivery item name
    if (Number(deliveryPriceData.unit_amount) === 0) {
      deliveryProductData.name = 'Free Collection in Dublin 10'
    }
    else {
      deliveryProductData.name = 'Delivery'
    }

    // delivery description string
    let deliveryDescription = ''

    let deliveriesList = {}

    let allDeliveryFields = document.querySelectorAll('select[data-field-type="delivery_type"]')

    allDeliveryFields.forEach(item => {
      if (deliveriesList[item.value] > 0 ) {
        deliveriesList[item.value] = Number(deliveriesList[item.value]) + 1
      }
      else {
        deliveriesList[item.value] = 1
      }
    })

    // X Package(s) for Collection — FREE
    if (deliveriesList['collection'] && deliveriesList['collection'] > 0) {
      deliveryDescription += String(deliveriesList['collection']) + ' Package(s) for Collection - FREE | '
    }

    // X Package(s) for Delivery to Billing Address — €X incl. VAT
    if (deliveriesList['delivery_billing_address'] && deliveriesList['delivery_billing_address'] > 0) {
      deliveryDescription += String(deliveriesList['delivery_billing_address']) + ' Package(s) for Delivery to Billing Address — \u20ac' + nationwideDeliveryCharge + ' | '
    }

    // X Package(s) for Delivery to Recipient(s) — €X each incl. VAT
    if (deliveriesList['delivery_gift_recipient'] && deliveriesList['delivery_gift_recipient'] > 0) {
      deliveryDescription += String(deliveriesList['delivery_gift_recipient']) + ' Package(s) for Delivery to Gift Recipient(s) — \u20ac' + nationwideDeliveryCharge + ' each | '
    }

    // delivery metadata object
    let deliveryMetadata = { type: 'delivery' }

    // Add nested sub-arrays/objects
    deliveryProductData.metadata = deliveryMetadata
    deliveryProductData.description = deliveryDescription.slice(0, deliveryDescription.lastIndexOf(' | '))
    deliveryPriceData.product_data = deliveryProductData
    deliveryLineItem.price_data = deliveryPriceData

    lineItems.push(deliveryLineItem)

    // Add arrays and objects to the data object
    data.metadata = metadata

    data.line_items = lineItems

    // console.log(data)

    // Send request and await sessionId
    const response = await fetch('/.netlify/functions/stripe-checkout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(res => res.json())

    const stripe = await loadStripe(response.publishableKey)

    const { error } = await stripe.redirectToCheckout({
      sessionId: response.sessionId,
    })

    if (error) {
      console.error(error)
    }
  }

  // Build page if possible
  if (cart && cart.length) {
    let spread = {}

    // Convert cart into a cart spread
    cart.forEach(item => {
      spread[item.id] = []

      for (let i = 0; i < item.quantity; i++) {
        let _item = []

        _item = item

        _item['uniqueId'] = item.id + '_' + ( Number(i) + 1 ).toString()

        _item['index'] = i + 1

        _item['inferredProductType'] = 'Product'

        if ( item.name.toLowerCase().includes('hamper') ) {
          _item['inferredProductType'] = 'Hamper'
        }

        if (item.name.toLowerCase().includes('box')) {
          _item['inferredProductType'] = 'Box'
        }

        spread[item.id].push({..._item})
      }
    })

    return (
      <PageTemplate 
        title={pageProps.title}
        subtitle={pageProps.subtitle}
        pageIdentifier={pageProps.pageIdentifier}
        pageClass={pageProps.pageClass}
        showCta={pageProps.showCta}
        showContactForm={pageProps.showContactForm}
        notNarrow={true}
      >
        <p className={`has-text-centered ${CheckoutPageStyles.subtitle || ''}`}>
          <strong className='title is-4'>Order Type: <u>Within Ireland</u></strong> <Button type={`is-secondary ${CheckoutPageStyles.changeButton || ''}`} url='/checkout' text='Change' />
        </p>
        <form
          className={'pay-form ' + (CheckoutPageStyles.form || '')}
          onSubmit={event => processForm(event)}
        >
          {/* Cart & Delivery Types */}
          <ul className={CheckoutPageStyles.cart || ''}>
            {cart.map(item => {
              const price = roundAndFix(item.price)
              const adjustedPrice = price.toString().split('.')

              return (
                <li key={item.id} className={CheckoutPageStyles.product || ''}>
                  {/* Image & Details Split */}
                  <div className={CheckoutPageStyles.image || ''}>
                    <ProductImageById id={item.id} />
                  </div>

                  <div className={CheckoutPageStyles.details || ''}>
                    {/* Product Name */}
                    <strong className={`title is-4 ${CheckoutPageStyles.productName}`}>{item.name}</strong>

                    {/* Product Price */}
                    <p className={CheckoutPageStyles.productPrice}>
                      Price: <strong><i dangerouslySetInnerHTML={{ __html: currencySymbol }} /><b><span>{convertCurrency(adjustedPrice[0])}</span>{/*<small>{adjustedPrice[1]}</small>*/}</b></strong> <small>each</small>
                    </p>

                    {/* Quantity Widget */}
                    <p className={CheckoutPageStyles.quantityWidget}>
                      Quantity: <strong>{item.quantity}</strong>
                      <span
                        className={`tag ${CheckoutPageStyles.increase || ''}`}
                        onClick={() => {
                          changeQty('increase', item.id)
                        }}
                        onKeyDown={() => {
                          return false
                        }}
                        role="button"
                        tabIndex="0"
                      >
                        +
                      </span>
                      <span
                        className={`tag ${CheckoutPageStyles.decrease || ''}`}
                        onClick={() => {
                          changeQty('decrease', item.id)
                        }}
                        onKeyDown={() => {
                          return false
                        }}
                        role="button"
                        tabIndex="0"
                      >
                        –
                      </span>
                    </p>

                    <p className={CheckoutPageStyles.personaliseNotice || ''}>Personalise your hampers and boxes below!</p>

                    {/* Spread Single Product Options */}
                    <div className={CheckoutPageStyles.spreadProductsHolder || ''}>
                      {spread[item.id].map(subItem => {
                        return (
                          <div 
                            key={subItem.uniqueId} 
                            className={`columns is-multiline single-product-box ${CheckoutPageStyles.subItem || ''}`} 

                            // Data attributes
                            data-id={item.id}
                            data-unique-id={subItem.uniqueId}
                            data-unit-price={price}
                            data-product-name={item.name}
                            data-product-index={subItem.index}
                          >
                            {/* Details label */}
                            <strong className={CheckoutPageStyles.subItemLabel}>{subItem.inferredProductType} {subItem.index} Details</strong>

                            {/* Collection or delivery? */}
                            <div className='field column is-full'>
                              <label htmlFor={'delivery_type_' + subItem.uniqueId} className='label'>
                                Collection or delivery?
                              </label>
                              <div className='control'>
                                <div className='select is-secondary'>
                                  <select // eslint-disable-line jsx-a11y/no-onchange
                                    onChange={event => { 
                                      calculateDeliveryRecipients() 
                                      updateDeliveryTypesPerItem(subItem.uniqueId, event.target.value)
                                      generateDeliveriesList()

                                      if ( event.target.value === 'delivery_gift_recipient') {
                                        event.target.scrollIntoView()
                                      }
                                    }}
                                    name={'delivery_type_' + subItem.uniqueId}
                                    data-field-type='delivery_type'
                                    defaultValue={deliveriesList['delivery_type_' + subItem.uniqueId]}
                                  >
                                    <option value="collection">Free Collection in Dublin 10</option>
                                    <option value="delivery_billing_address">Deliver to my billing address (&euro;{nationwideDeliveryCharge} per order)</option>
                                    <option value="delivery_gift_recipient">Deliver to gift recipient (&euro;{nationwideDeliveryCharge})</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            {/* Recipient person or company name */}
                            <div className='field column is-full'>
                              <label htmlFor={'name_' + subItem.uniqueId} className='label'>
                                Recipient name or company {deliveryTypesPerItem[subItem.uniqueId] !== 'delivery_gift_recipient' && <small className="tag is-secondary has-text-white">optional</small> }
                              </label>
                              <div className='control'>
                                <input
                                  className='input is-secondary'
                                  type='text'
                                  name={'name_' + subItem.uniqueId}
                                  placeholder=''
                                />
                              </div>
                            </div>

                            {/* Full delivery address */}
                            <div className={`field column is-full ${deliveryTypesPerItem[subItem.uniqueId] === 'delivery_gift_recipient' ? '' : 'is-hidden'}`}>
                              <label htmlFor={'address_' + subItem.uniqueId} className='label'>
                                Full delivery address
                              </label>
                              <div className='control'>
                                <input
                                  className='input is-secondary'
                                  type='text'
                                  name={'address_' + subItem.uniqueId}
                                  placeholder=''
                                />
                              </div>
                            </div>

                            {/* Town/City */}
                            <div className={`field column is-half ${deliveryTypesPerItem[subItem.uniqueId] === 'delivery_gift_recipient' ? '' : 'is-hidden'}`}>
                              <label htmlFor={'town_city_' + subItem.uniqueId} className='label'>
                                Town/City
                              </label>
                              <div className='control'>
                                <input
                                  className='input is-secondary'
                                  type='text'
                                  name={'town_city_' + subItem.uniqueId}
                                  placeholder=''
                                />
                              </div>
                            </div>

                            {/* County */}
                            <div className={`field column is-half ${deliveryTypesPerItem[subItem.uniqueId] === 'delivery_gift_recipient' ? '' : 'is-hidden'}`}>
                              <label htmlFor={'county_' + subItem.uniqueId} className='label'>
                                County
                              </label>
                              <div className='control'>
                                <div className='select is-secondary'>
                                  <select // eslint-disable-line jsx-a11y/no-onchange
                                    name={'county_' + subItem.uniqueId}
                                    onChange={event => {
                                      updateCountiesWithTimingPerItem(subItem.uniqueId, event.target.value)
                                    }}
                                  >
                                    <option>Dublin</option>
                                    <option>Antrim</option>
                                    <option>Armagh</option>
                                    <option>Carlow</option>
                                    <option>Cavan</option>
                                    <option>Clare</option>
                                    <option>Cork</option>
                                    <option>Derry</option>
                                    <option>Donegal</option>
                                    <option>Down</option>
                                    <option>Fermanagh</option>
                                    <option>Galway</option>
                                    <option>Kerry</option>
                                    <option>Kildare</option>
                                    <option>Kilkenny</option>
                                    <option>Laois</option>
                                    <option>Leitrim</option>
                                    <option>Limerick</option>
                                    <option>Longford</option>
                                    <option>Louth</option>
                                    <option>Mayo</option>
                                    <option>Meath</option>
                                    <option>Monaghan</option>
                                    <option>Offaly</option>
                                    <option>Roscommon</option>
                                    <option>Sligo</option>
                                    <option>Tipperary</option>
                                    <option>Tyrone</option>
                                    <option>Waterford</option>
                                    <option>Westmeath</option>
                                    <option>Wexford</option>
                                    <option>Wicklow</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            {/* Eircode */}
                            <div className={`field column is-half ${deliveryTypesPerItem[subItem.uniqueId] === 'delivery_gift_recipient' ? '' : 'is-hidden'}`}>
                              <label htmlFor={'eircode_' + subItem.uniqueId} className='label'>
                                Eircode
                              </label>
                              <div className='control'>
                                <input
                                  className='input is-secondary'
                                  type='text'
                                  name={'eircode_' + subItem.uniqueId}
                                  placeholder=''
                                />
                              </div>
                            </div>

                            {/* Recipient Phone */}
                            <div className={`field column is-half ${deliveryTypesPerItem[subItem.uniqueId] === 'delivery_gift_recipient' ? '' : 'is-hidden'}`}>
                              <label htmlFor={'phone_' + subItem.uniqueId} className="label">
                                Phone No.
                              </label>
                              <div className='control'>
                                <input
                                  className='input is-secondary'
                                  type='tel'
                                  name={'phone_' + subItem.uniqueId}
                                  placeholder=''
                                  pattern='[0-9 +]*'
                                />
                              </div>
                            </div>

                            {/* Delivery date */}
                            <div className={`field column is-half ${deliveryTypesPerItem[subItem.uniqueId] === 'delivery_gift_recipient' ? '' : 'is-hidden'} ${((subItem.uniqueId in countiesWithTimingPerItem) && !(countiesWithTiming.includes(countiesWithTimingPerItem[subItem.uniqueId]))) ? 'is-hidden' : ''}`}>
                              <label htmlFor={'delivery_date_' + subItem.uniqueId} className='label'>
                                Delivery date
                              </label>
                              <div className='control'>
                                <input
                                  className='input is-secondary'
                                  name='date'
                                  type='date'
                                  placeholder='DD/MM/YYYY'
                                  pattern='(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)'
                                  min={minDate}
                                  max={maxDate}
                                  defaultValue={minDate}
                                />
                              </div>
                            </div>

                            {/* Delivery time */}
                            <div className={`field column is-half ${deliveryTypesPerItem[subItem.uniqueId] === 'delivery_gift_recipient' ? '' : 'is-hidden'} ${((subItem.uniqueId in countiesWithTimingPerItem) && !(countiesWithTiming.includes(countiesWithTimingPerItem[subItem.uniqueId]))) ? 'is-hidden' : ''}`}>
                              <label htmlFor={'delivery_time_' + subItem.uniqueId} className='label'>
                                Delivery time
                              </label>
                              <div className='control'>
                                <div className='select is-secondary'>
                                  <select // eslint-disable-line jsx-a11y/no-onchange
                                    name={'delivery_time_' + subItem.uniqueId}
                                  >
                                    <option value="any">Anytime (8am-5pm)</option>
                                    <option value="morning">Morning (8am-12pm)</option>
                                    <option value="afternoon">Afternoon (12pm-5pm)</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            {/* Personalised message */}
                            <div className='field column is-full'>
                              <label htmlFor={'message_' + subItem.uniqueId} className="label">
                                Personalised message <small className="tag is-secondary has-text-white">optional</small>
                              </label>
                              <div className='control'>
                                <input
                                  className='input is-secondary'
                                  type='tel'
                                  name={'message_' + subItem.uniqueId}
                                  placeholder=''
                                />
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>

          {/* Billing Form & Totals */}
          <h2 className="title is-2">Billing Address</h2>
          <p className={`is-narrow ${CheckoutPageStyles.billingInfo || ''}`}>The invoice will be issued using these details. If you selected "Deliver to billing address" for one or more products, we will deliver them together to the address specified here.</p>

          <div className={`billing-form ${CheckoutPageStyles.billingForm || ''}`} style={{ width: '100%' }}>
            <div className={`container is-narrow ${CheckoutPageStyles.billingFormInner || ''}`}>
              <div className='columns is-multiline'>
                {/* Billing full name */}
                <div className='field column is-half'>
                  <label htmlFor='billing_name' className='label'>
                    Full Name
                  </label>
                  <div className='control'>
                    <input
                      className='input'
                      type='text'
                      name='billing_name'
                      placeholder=''
                    />
                  </div>
                </div>

                {/* Billing company */}
                <div className='field column is-half'>
                  <label htmlFor='billing_company' className='label'>
                    Company <small className="tag is-secondary has-text-white">optional</small>
                  </label>
                  <div className='control'>
                    <input
                      className='input'
                      type='text'
                      name='billing_company'
                      placeholder=''
                    />
                  </div>
                </div>

                {/* Billing phone number */}
                <div className='field column is-half'>
                  <label htmlFor='billing_phone' className="label">
                    Phone No.
                  </label>
                  <div className='control'>
                    <input
                      className='input'
                      type='tel'
                      name='billing_phone'
                      placeholder=''
                      pattern='[0-9 +]*'
                    />
                  </div>
                </div>

                {/* Billing email */}
                <div className='field column is-half'>
                  <label htmlFor='billing_email' className='label'>
                    E-mail address
                  </label>
                  <div className='control'>
                    <input
                      className='input'
                      type='email'
                      name='billing_email'
                      placeholder=''
                    />
                  </div>
                </div>

                {/* Billing full address */}
                <div className='field column is-full'>
                  <label htmlFor='billing_address' className='label'>
                    Full billing address
                  </label>
                  <div className='control'>
                    <input
                      className='input'
                      type='text'
                      name='billing_address'
                      placeholder=''
                    />
                  </div>
                </div>

                {/* Billing city/town */}
                <div className='field column is-half'>
                  <label htmlFor='billing_town_city' className='label'>
                    Town/City
                  </label>
                  <div className='control'>
                    <input
                      className='input'
                      type='text'
                      name='billing_town_city'
                      placeholder=''
                    />
                  </div>
                </div>

                {/* Billing county */}
                <div className='field column is-half'>
                  <label htmlFor='billing_county' className='label'>
                    County
                  </label>
                  <div className='control'>
                    <div className='select'>
                      <select // eslint-disable-line jsx-a11y/no-onchange
                        name='billing_county'
                        onChange={event => {
                          setBillingDeliveryCounty(event.target.value)
                        }}
                      >
                        <option>Dublin</option>
                        <option>Antrim</option>
                        <option>Armagh</option>
                        <option>Carlow</option>
                        <option>Cavan</option>
                        <option>Clare</option>
                        <option>Cork</option>
                        <option>Derry</option>
                        <option>Donegal</option>
                        <option>Down</option>
                        <option>Fermanagh</option>
                        <option>Galway</option>
                        <option>Kerry</option>
                        <option>Kildare</option>
                        <option>Kilkenny</option>
                        <option>Laois</option>
                        <option>Leitrim</option>
                        <option>Limerick</option>
                        <option>Longford</option>
                        <option>Louth</option>
                        <option>Mayo</option>
                        <option>Meath</option>
                        <option>Monaghan</option>
                        <option>Offaly</option>
                        <option>Roscommon</option>
                        <option>Sligo</option>
                        <option>Tipperary</option>
                        <option>Tyrone</option>
                        <option>Waterford</option>
                        <option>Westmeath</option>
                        <option>Wexford</option>
                        <option>Wicklow</option>
                      </select>
                    </div>
                  </div>
                </div>

                {/* Billing Eircode */}
                <div className='field column is-half'>
                  <label htmlFor='billing_eircode' className='label'>
                    Eircode
                  </label>
                  <div className='control'>
                    <input
                      className='input'
                      type='text'
                      name='billing_eircode'
                      placeholder=''
                    />
                  </div>
                </div>

                {/* Voucher */}
                <div className={`field column is-half ${CheckoutPageStyles.voucher || ''}`}>
                  <label htmlFor='billing_voucher' className='label'>
                    Voucher
                  </label>
                  <div className='control'>
                    <input
                      className='input'
                      type='text'
                      name='billing_voucher'
                      placeholder=''
                      disabled={discountApplied === 0.05 ? true : false}
                    />
                    <span
                      className={`tag is-secondary ${CheckoutPageStyles.applyVoucher || ''}`}
                      onClick={() => {
                        setDiscountApplied(calculateDiscount(true))
                      }}
                      onKeyDown={() => {
                        return false
                      }}
                      role="button"
                      tabIndex="0"
                    >
                      Apply
                    </span>
                  </div>
                </div>

                {/* Delivery date */}
                <div className={`field column is-half ${Object.values(deliveriesList).indexOf('delivery_billing_address') > -1 ? '' : 'is-hidden'} ${(!(countiesWithTiming.includes(billingDeliveryCounty))) ? 'is-hidden' : ''}`}>
                  <label htmlFor='delivery_date_billing' className='label'>
                    Delivery date
                  </label>
                  <div className='control'>
                    <input
                      className={`input is-secondary ${Object.values(deliveriesList).indexOf('delivery_billing_address') > -1 ? '' : 'parent-hidden'} ${(!(countiesWithTiming.includes(billingDeliveryCounty))) ? 'parent-hidden' : ''}`}
                      name='delivery_date_billing'
                      type='date'
                      placeholder='DD/MM/YYYY'
                      pattern='(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)'
                      min={minDate}
                      max={maxDate}
                      defaultValue={minDate}
                    />
                  </div>
                </div>

                {/* Delivery time */}
                <div className={`field column is-half ${Object.values(deliveriesList).indexOf('delivery_billing_address') > -1 ? '' : 'is-hidden'} ${(!(countiesWithTiming.includes(billingDeliveryCounty))) ? 'is-hidden' : ''}`}>
                  <label htmlFor='delivery_time_billing' className='label'>
                    Delivery time
                  </label>
                  <div className='control'>
                    <div className='select is-secondary'>
                      <select // eslint-disable-line jsx-a11y/no-onchange
                        name='delivery_time_billing'
                      >
                        <option value="any">Anytime (8am-5pm)</option>
                        <option value="morning">Morning (8am-12pm)</option>
                        <option value="afternoon">Afternoon (12pm-5pm)</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`container is-narrow ${CheckoutPageStyles.actions || ''}`}>
            <div>
              <ul className={CheckoutPageStyles.prices || ''}>
                <li>Subtotal ({totalQuantity} products): <strong><i dangerouslySetInnerHTML={{ __html: currencySymbol }} />{convertCurrency(amountSubtotal)}</strong></li>
                {deliveryRecipientsCount > 0 && <li>Delivery ({deliveryRecipientsCount > 1 ? deliveryRecipientsCount + ' recipients' : '1 recipient'}): <strong><i dangerouslySetInnerHTML={{ __html: currencySymbol }} />{convertCurrency(amountDelivery)}</strong></li>}
                {amountDiscount > 0 && <li className={CheckoutPageStyles.discount || ''}>Discount: <strong><i dangerouslySetInnerHTML={{ __html: currencySymbol }} />{convertCurrency(amountDiscount)}</strong></li>}
                {/* <li>VAT (23%): <strong>&euro;{amountVat}</strong></li> */}
                <li className={CheckoutPageStyles.finalTotal || ''}>Total: <strong><i dangerouslySetInnerHTML={{ __html: currencySymbol }} />{Number(convertCurrency(amountSubtotal)) + Number(convertCurrency(amountDelivery)) - Number(convertCurrency(amountDiscount))}</strong></li>
                {selectedCurrency !== 'EUR' && <small className={CheckoutPageStyles.totalInEurOnly || ''}>Your card will be billed in EUR (&euro;{amountTotal})</small>}
              </ul>
              
              {/* Pay Now Button */}
              <button className={`button is-secondary is-strong ${CheckoutPageStyles.submit || ''}`} disabled={submitButtonDisabled}>
                Pay Now
              </button>
            </div>
          </div>
        </form>

        {/* Container for popup error messages */}
        <ToastContainer />
      </PageTemplate>
    )
  }
  else {
    return (
      <PageTemplate 
        title={pageProps.title}
        subtitle={pageProps.subtitle}
        pageIdentifier={pageProps.pageIdentifier}
        pageClass={pageProps.pageClass}
        showCta={pageProps.showCta}
        showContactForm={pageProps.showContactForm}
      >
        <div className="has-text-centered">
          <p className="has-text-center has-text-primary" style={{marginBottom: '32px'}}>
            <strong>Your cart is empty.</strong>
          </p>
          <Button url="/" text="Shop Now" class="is-secondary is-strong" />
        </div>
      </PageTemplate>
    )
  }
}

export default PaymentPage
